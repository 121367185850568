document.addEventListener('alpine:init', () => {
    Alpine.data('mobileSlider', () => ({
        cards: [],
        activeCard: 0,

        show: {
            ['x-intersect.margin.-50']() {
                this.setup()
                this.handleScroll()
            }
        },

        init: function () {
            document.addEventListener('resize', this.setup)
            this.cards = Array.from(this.$refs.grid.children)
            this.setup()
            this.handleScroll()
        },

        setup: function () {
            if (window.innerWidth < 500) {
                this.$refs.grid.style.overflowX = 'scroll'
                this.$refs.grid.style.display = 'flex'
                this.$refs.grid.style.scrollSnapType = 'x mandatory'
                this.$refs.grid.classList.remove('gap-9')
                this.$refs.grid.classList.add('hide-scrollbar')
                this.$refs.pagination.classList.remove('hidden')


                this.$nextTick(() => {
                    this.cards.forEach((child) => {
                        child.style.width = '80vw'
                        child.style.flexShrink = 0
                        child.style.marginLeft = '1vw'
                        child.style.marginRight = '1vw'
                        child.style.scrollSnapAlign = 'center'
                    })
                })
            } else {
                this.$refs.grid.classList.add('gap-9')
                this.$refs.pagination.classList.add('hidden')
                this.$refs.grid.classList.remove('hide-scrollbar')

                this.$refs.grid.style = null
                this.cards.forEach((child) => {
                    child.style = null
                })
            }

        },

        handleScroll: function () {
            const cardWidth = this.cards[0].clientWidth
            const scrollPos = this.$refs.grid.scrollLeft + cardWidth
            this.activeCard = Math.round(scrollPos / cardWidth)
            /* NB: adding this here as setup needs to be done after animation is complete */
            this.setup()
        },

        nextClick: function () {
            const cardWidth = this.cards[0].clientWidth
            const scrollPos = this.$refs.grid.scrollLeft
            this.$refs.grid.scrollTo({left: scrollPos + cardWidth, behavior: "smooth"})
        },

        prevClick: function () {
            const cardWidth = this.cards[0].clientWidth
            const scrollPos = this.$refs.grid.scrollLeft
            this.$refs.grid.scrollTo({left: scrollPos - cardWidth, behavior: "smooth"})
        }
    }))
})
